<template>
    <TextAndCarousel
        v-if="styleKey === VARIANTS.IMAGE"
        :title="config.heading_text"
        :text="config.text_text"
        :images="config.photo_images"
        :button-label="config.button_button_text"
        :button-url="buttonUrl"
        :no-index="config.button_close_from_index"
        :stretched="config.full_height"
        :padding-y="config.block_padding_y"
        :bg-color="bgColor"
        :bg-image="config.background_url_image"
        :bg-opacity="bgOpacity"
        :inverted="!!config.inverted_font_color"
    />

    <TextAndForm
        v-else-if="styleKey === VARIANTS.TEXT_N_FORM"
        :pivot-id="pivotId"
        :title="config.heading_text"
        :text="config.text_text"
        :button-label="config.button_button_text"
        :button-url="buttonUrl"
        :no-index="config.button_close_from_index"
        :form-title="config.form_heading_text"
        :form-text="config.form_text_text"
        :form-fields="config.form_fields || []"
        :form-button-label="config.form_button_label"
        :form-button-after-submit="config.form_button_message_after_submit"
        :padding-y="config.block_padding_y"
        :bg-color="bgColor"
        :bg-image="config.background_url_image"
        :bg-opacity="bgOpacity"
        :inverted="!!config.inverted_font_color"
        :reversed="config.content_location_for_text_and_form === 'form_left'"
    />

    <TextAndImage
        v-else-if="styleKey === VARIANTS.TEXT_N_IMAGE"
        :title="config.heading_text"
        :text="config.text_text"
        :image="config.one_photo_image"
        :button-label="config.button_button_text"
        :button-url="buttonUrl"
        :no-index="config.button_close_from_index"
        :stretched="config.full_height"
        :padding-y="config.block_padding_y"
        :bg-color="bgColor"
        :bg-image="config.background_url_image"
        :bg-opacity="bgOpacity"
        :reversed="config.content_location_for_text_and_photo === 'photo_left'"
        :inverted="!!config.inverted_font_color"
    />

    <TextOnImage
        v-else
        :title="config.heading_text"
        :text="config.text_text"
        :button-label="config.button_button_text"
        :button-url="buttonUrl"
        :no-index="config.button_close_from_index"
        :stretched="config.full_height"
        :padding-y="config.block_padding_y"
        :bg-color="bgColor"
        :bg-image="config.background_url_image"
        :bg-opacity="bgOpacity"
        :align="config.content_location_for_with_photo_in_background"
        :inverted="!!config.inverted_font_color"
    />
</template>

<script>
const VARIANTS = {
  BACKGROUND_IMAGE: 'with_a_photo_on_the_background',
  IMAGE: 'with_a_photo_in_the_center',
  TEXT_N_FORM: 'text_and_form',
  TEXT_N_IMAGE: 'text_and_photo'
}

export default {
  name: 'BlockCover',

  components: {
    TextAndCarousel: () => import('./BlockCover/TextAndCarousel'),
    TextAndForm: () => import('./BlockCover/TextAndForm'),
    TextAndImage: () => import('./BlockCover/TextAndImage'),
    TextOnImage: () => import('./BlockCover/TextOnImage')
  },

  props: {
    pivotId: {
      type: [Number, String],
      default: null
    },

    styleKey: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    VARIANTS: () => VARIANTS,

    /**
     * @returns {string}
     */
    bgColor () {
      return this.config.type_background === 'color'
        ? this.config.background_color
        : this.config.background_image_color
    },

    bgOpacity () {
      return this.config.image_overlap
        ? '50%'
        : this.config.type_background === 'color'
          ? this.config.background_opacity
          : this.config.background_image_opacity
    },

    buttonUrl () {
      return this.$store.state.admin
        ? `/${this.$store.state.siteCode}/preview${this.config.button_button_link}`
        : this.config.button_button_link
    }
  }
}
</script>
